<script lang="ts">
  import { onMount } from 'svelte';

  export let onVisible: () => void = () => {};
  export let root: HTMLElement | null = null;

  let self: HTMLElement;

  onMount(() => {
    const io = new IntersectionObserver(
      (es) => {
        if (es[0]?.isIntersecting) {
          onVisible();
          return;
        }
      },
      {
        root,
        rootMargin: '0px',
      },
    );
    io.observe(self);
  });
</script>

<div class="scroll-lasting-view-checker" bind:this={self} />

<style>
  .scroll-lasting-view-checker {
    width: 100%;
    height: 1rem;
  }
</style>
